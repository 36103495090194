.tw-badge {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 15px;
  padding: 1px 7px 0;
  height: min-content;
  user-select: text;

  &.light {
    background: #eeeeee;
    color: gray;
  }
  &.ultralight {
    background: #fff;
    color: gray;
    border: 1px solid gray;
  }
  &.dark {
    background: gray;
    color: white;
  }
}

@layer tailwind-base {
  @tailwind base;
}