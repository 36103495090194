.tw-badge {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 15px;
  padding: 1px 7px 0;
  height: min-content;
  user-select: text;
}
.tw-badge.light {
  background: #eeeeee;
  color: gray;
}
.tw-badge.ultralight {
  background: #fff;
  color: gray;
  border: 1px solid gray;
}
.tw-badge.dark {
  background: gray;
  color: white;
}

@layer tailwind-base {
  @tailwind base;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL0M6L1VzZXJzL2EubmVzdGEvRG9jdW1lbnRzL0phdmFzY3JpcHQlMjBXb3Jrc3BhY2UvdGFnLWFwcC13ZWIvc3JjL2Fzc2V0cy9zY3NzL3RhaWx3aW5kL3RhaWx3aW5kLWJhc2Uuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUVGO0VBQ0U7RUFDQTtFQUNBOztBQUVGO0VBQ0U7RUFDQTs7O0FBSUo7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi50dy1iYWRnZSB7XHJcbiAgZGlzcGxheTogLXdlYmtpdC1ib3g7XHJcbiAgZGlzcGxheTogLW1vei1ib3g7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICAtd2Via2l0LWxpbmUtY2xhbXA6IDE7XHJcbiAgLXdlYmtpdC1ib3gtb3JpZW50OiB2ZXJ0aWNhbDtcclxuICBvdmVyZmxvdzogaGlkZGVuO1xyXG4gIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xyXG4gIGJvcmRlci1yYWRpdXM6IDE1cHg7XHJcbiAgcGFkZGluZzogMXB4IDdweCAwO1xyXG4gIGhlaWdodDogbWluLWNvbnRlbnQ7XHJcbiAgdXNlci1zZWxlY3Q6IHRleHQ7XHJcblxyXG4gICYubGlnaHQge1xyXG4gICAgYmFja2dyb3VuZDogI2VlZWVlZTtcclxuICAgIGNvbG9yOiBncmF5O1xyXG4gIH1cclxuICAmLnVsdHJhbGlnaHQge1xyXG4gICAgYmFja2dyb3VuZDogI2ZmZjtcclxuICAgIGNvbG9yOiBncmF5O1xyXG4gICAgYm9yZGVyOiAxcHggc29saWQgZ3JheTtcclxuICB9XHJcbiAgJi5kYXJrIHtcclxuICAgIGJhY2tncm91bmQ6IGdyYXk7XHJcbiAgICBjb2xvcjogd2hpdGU7XHJcbiAgfVxyXG59XHJcblxyXG5AbGF5ZXIgdGFpbHdpbmQtYmFzZSB7XHJcbiAgQHRhaWx3aW5kIGJhc2U7XHJcbn0iXX0= */